import * as jQuery from 'jquery';
(<any>window).$ = (<any>window).jQuery = jQuery;

import { initCookieConsent } from './cookie-consent';

$(document).ready(() => {
    initCookieConsent();
    const cookieAccept = document.querySelector('#acceptCookies');
    // Cookie toggle
    if (cookieAccept != null) {
        cookieAccept.addEventListener('click', () => {
            $('.cookie-information').toggleClass('accepted');
        })
    }
})

